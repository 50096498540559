// BURGER NAV
// ICON
function updateMenuButton() {
	$('.burger').find('.burger-icon').toggleClass('is-active');
}
$(document).ready(function() {
	$('.burger').click(function(e){
		e.preventDefault();
		updateMenuButton();
	});
});
// ------------------
// MENU HIDE
$(".burger").click(function() {
    $(".nav").toggleClass("show");

    $(this).toggleClass("close");
});
//

// ACCORDION NAV
jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');

	if( accordionsMenu.length > 0 ) {
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('ul').attr('style', 'display:none;').slideDown(300) : checkbox.siblings('ul').attr('style', 'display:block;').slideUp(300);
			});
		});
	}
});
//

// HOME BOX PARALLAX
var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
// console.log(width)
if(width > 550) {

  $('.home--one').paroller({
    factor: -0.05,
    factorSm: -0.02,
    factorXs: 1000,
    type: 'foreground',
    direction: 'vertical'
  });

  $('.home--two').paroller({
    factor: 0.1,
    factorSm: 0.02,
    factorXs: 1000,
    type: 'foreground',
    direction: 'vertical'
  });

  $('.home--three').paroller({
    factor: -0.05,
    factorSm: -0.02,
    factorXs: 1000,
    type: 'foreground',
    direction: 'vertical'
  });

  $('.home--products-block').paroller({
    factor: 0.1,
    type: 'foreground',
    direction: 'vertical'
  });

}

// if(width > 750) {
//   $('.test').paroller({
//     factor: 0.7,
//     factorMd: 3,
//     type: 'foreground',
//     direction: 'vertical'
//   });
// }

//

// SMOOTH ANCHOR SCROLLING
$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 750);
});
//
